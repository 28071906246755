var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useState } from 'react';
import { cn } from '../utils';
import { FaqBullet } from './FaqBullet';
export const Faqs = forwardRef((_a, ref) => {
    var { className, variant = 'primary', faqs } = _a, props = __rest(_a, ["className", "variant", "faqs"]);
    const [openIndex, setOpenIndex] = useState(-1);
    const handleClick = (index) => {
        if (openIndex === index) {
            setOpenIndex(-1);
        }
        else {
            setOpenIndex(index);
        }
    };
    return (_jsxs("div", Object.assign({ ref: ref, className: cn(
        // Base styles
        'flex flex-col w-full', 
        // Variant styles
        {
            'text-on-background': variant === 'primary',
            'text-on-background-alt': variant === 'footer',
        }, 
        // Custom className comes last to allow overrides
        className), "data-component": "Faqs" }, props, { children: [_jsx("h1", { className: "heading-4 pb-6", children: "FAQs" }), _jsx("ul", { className: "list-none flex flex-col w-full", children: faqs.map((faq, index) => FaqBullet(Object.assign(Object.assign({}, faq), { index,
                    handleClick, open: index === openIndex }))) })] })));
});
Faqs.displayName = 'Faqs';

import { isNotInProd } from '../envUtils'
import { createLog } from './createLog'
import { getExternalLogger } from './getExternalLogger'
import { logLevel } from './types'

let enabled = true

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const doLog = (level: logLevel, codeLocation: string, message: string, data: any): void => {
  if (!enabled || typeof window === 'undefined') {
    // window test prevents running server-side
    return
  }
  const loggerObj = getExternalLogger()
  const log = createLog(codeLocation, message, data)
  switch (level) {
    case 'warn':
      loggerObj?.warn(log)
      /* eslint-disable-next-line no-restricted-syntax */
      console.warn(log)
      break
    case 'debug':
      loggerObj?.debug(log)
      if (isNotInProd()) {
        /* eslint-disable-next-line no-console */
        console.debug(log)
      }
      break
    case 'error':
      // We have forwardErrorsToLogs set to true, so don't need to use the external logger
      /* eslint-disable-next-line no-restricted-syntax */
      console.error(log)
      break
    default:
      loggerObj?.log(log)
      /* eslint-disable-next-line no-restricted-syntax */
      console.log(log)
  }
}

export const Logger = {
  of: (codeLocation: string) => {
    return {
      info: (message: string, data?: any) => doLog('info', codeLocation, message, data),
      warn: (message: string, data?: any) => doLog('warn', codeLocation, message, data),
      error: (message: string, data?: any) => doLog('error', codeLocation, message, data),
      debug: (message: string, data?: any) => doLog('debug', codeLocation, message, data),
    }
  },
}

export const enableLogger = (isEnabled: boolean) => {
  enabled = isEnabled
}

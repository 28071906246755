import { Faqs } from '@grandstand-web/ui'
import Link from 'next/link'
import styled from 'styled-components'
import { TeamData } from './Footer'

interface DropdownProps {
  title: string
  teams: TeamData[] | undefined
}

export interface PartnerTeams {
  NBA: TeamData[]
  NHL: TeamData[]
  MLB: TeamData[]
  WNBA: TeamData[]
}

const DropdownWrapper = styled.div<{ isOpen: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--divider-canvas);
  border-bottom: ${(props) => (props.isOpen ? '0' : '1px solid var(--divider-canvas)')};
  padding-top: 24px;
  padding-bottom: ${(props) => (props.isOpen ? '0' : '24px')};
  cursor: pointer;
  width: 100%;
`

const Text = styled.p`
  color: var(--on-light-alt);
  font-size: 20px;
  @media screen and (max-width: 425px) {
    font-size: 14px;
  }
`

const DropdownContent = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  border-bottom: 1px solid var(--divider-canvas);
  padding-bottom: 24px;
  font-size: 20px;
  max-width: 1728px;
  @media screen and (max-width: 425px) {
    font-size: 14px;
  }
`

const TeamsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 15px;
  word-wrap: normal;
  max-width: 1728px;
  overflow-wrap: break-word;
  list-style: none;
`
const LeagueText = styled.p`
  color: var(--on-light-alt);
`

const TeamLink = styled.li`
  color: var(--on-light-alt);
  &:last-child span {
    display: none;
  }
  &:not(:last-child) a:after {
    content: ', ';
    white-space: pre;
    text-decoration-line: none;
  }
  &:hover {
    /* color: var(--on-dark); */
    /* text-decoration-line: underline; */
  }
`

const FooterDropdown: React.FC<DropdownProps> = ({ title, teams }) => {
  const leagues: Array<keyof PartnerTeams> = ['NBA', 'NHL', 'MLB', 'WNBA']

  const dropdownItems = [
    {
      heading: title,
      text: (
        <>
          {leagues.map((league) => (
            <div key={league}>
              <LeagueText>{league.toUpperCase()}</LeagueText>
              <TeamsList>
                {teams
                  ?.filter((team) => team.partner === true && team.league === league)
                  .map((team: TeamData) => (
                    <TeamLink key={team.id}>
                      <Link href={`https://fanduelsportsnetwork.com/teams/${team.league}-${team.name}`}>
                        {team.picker_field}
                      </Link>
                    </TeamLink>
                  ))}
              </TeamsList>
            </div>
          ))}
        </>
      ),
    },
  ]

  return <Faqs faqs={dropdownItems} />
}

export default FooterDropdown
